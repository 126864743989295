import App, { AppContext, AppProps } from "next/app";
import "@fortawesome/fontawesome-svg-core/styles.css";
import LivePixApp from "@livepix/components/pages/_app";

export default function SiteApp(props: AppProps) {
  return <LivePixApp {...props} />;
}

SiteApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);

  // Get nonce from x-nonce header, set in the middleware
  const nonce = context.ctx.req?.headers["x-nonce"] ?? "";

  return { ...ctx, nonce };
};
