import React, { PropsWithChildren } from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { ProfileData } from "@livepix/sdk-js/types/profile";
import Logo from "@livepix/components/common/Logo";
import useWebservice from "@livepix/components/hooks/useWebservice";
import useEndpoint from "@livepix/components/hooks/useEndpoint";
import useUser from "@livepix/components/hooks/useUser";
import ProfileSubscriptions from "components/profile/ProfileSubscriptions";
import Account from "components/profile/AccountMenu";
import Profile from "components/profile";
import { Button } from "@mui/material";

const Container = styled.div<{
  backgroundColor: string;
  backgroundImage?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;

  background-color: ${(props) => props.backgroundColor};
  ${(props) => (props.backgroundImage ? `background-image: url("${props.backgroundImage}")` : "")};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  @media (max-width: 500px) {
    display: block;
    justify-content: baseline;
    padding: 80px 10px 30px;
  }
`;

const FloatingAccountContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .BrainhubCarousel__container {
    margin: 0 -15px;
    overflow: initial;
  }
`;

const AccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 400px;
  margin: 20px 0 0;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  background: white;
  color: #555;

  @media (max-width: 440px) {
    width: 100%;
  }
`;

const AccountText = styled.p`
  flex: 1;
  margin: 0 20px 0 0;
  font-size: 16px;
  line-height: 20px;
`;

const AccountButton = styled(Button)`
  margin: 0;
  width: 140px;
  box-shadow: none;
  text-transform: initial;

  &:hover {
    box-shadow: none;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 0;
  padding: 6px 9px 0;
  border-radius: 5px;
  background: #fff;
`;

const LogoContainer = styled.div`
  width: 60px;
`;

type Props = {
  profile: ProfileData;
  loading?: boolean;
  width?: string;
};

export default function ProfilePage({ profile, loading, width, children }: PropsWithChildren<Props>) {
  const router = useRouter();
  const endpoint = useEndpoint();
  const webservice = useWebservice();

  const user = useUser(false);

  React.useEffect(() => {
    webservice
      .post(`/profile/${profile.username}/tracking`, {
        url: window.location.href,
        referrer: document.referrer,
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <FloatingAccountContainer>
        <Account />
      </FloatingAccountContainer>
      <Container backgroundColor={profile.backgroundColor} backgroundImage={profile.backgroundImage || undefined}>
        <Content>
          <Profile profile={profile} loading={loading} width={width}>
            {children}
          </Profile>
          <ProfileSubscriptions profile={profile} width={width} />
          {!user && (
            <AccountContainer>
              <AccountText>Crie sua conta para registrar suas mensagens.</AccountText>
              <AccountButton
                href={endpoint.authUrl(`/signup?redirect=${endpoint.appUrl(router.asPath)}`)}
                variant="outlined"
                color="secondary"
              >
                Cadastrar-se
              </AccountButton>
            </AccountContainer>
          )}
          <Footer>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </Footer>
        </Content>
      </Container>
    </>
  );
}
